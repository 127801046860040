@font-face {
    font-family: 'Poppins';
    src: url('../../../public/Poppins-Light.ttf');
}
::-webkit-scrollbar {
    display: none;
}
h6{padding-top: 8px;
font-family: 'Poppins';}
h5{padding-top: 6px;
    font-family: 'Poppins';} 
    .Logo{width: 100%;} 
.container {
    display: flex;
    flex-direction: row;
    height: 60px;
    width: 100%;
    background-color: #FF5757;
}

.force {
    align-items: center;
    flex-direction: row;
    width: 75%;
    padding-top: 12px;
}

.padder {
    margin-left: 5%;
    width: 70%;
}

.MiddleSearch {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
        flex-direction: row;

}

.hasOn{opacity: 1;
animation: fade 1s;}
.hasOff{opacity: 0;
animation: infade 1s}
.drop{opacity: 1;
    animation: drop 1s;}
    .up{opacity: 0;
    animation: up 1s}
.sizeOn{
    animation: resizer 1s;
    width: 45%;    
}
    .sizeOff{
    animation: deresizer 1s;
    width: 20%;
}
.event {
    border: 2px solid white;
    border-radius: 8px;
    width: 15%;
}

.time {
    border: 2px solid white;
    border-radius: 8px;
    width: 15%;
}
.eventspe{
    background-color: whitesmoke;
    overflow: auto;
    z-index: 9;
    position: absolute;
    max-height: 40%;
    max-width: 50%;
    border: 4px solid #00004D;
    border-radius: 16px;
    width: 50%;
    color: black;
    text-align: center;
    margin-left: 15%; 
    font-family: 'Poppins';
}
.eventdat{ background-color: whitesmoke;
    overflow: auto;
    z-index: 9;
    position: absolute;
    max-width: 50%;
    border: 4px solid #00004D;
    border-radius: 16px;
    width: 50%;
    color: black;
    text-align: center;
    margin-left: 15%; 
    font-family: 'Poppins';}
.search{
    border: 4px solid #00004D;
    border-radius: 12px;
width: 80%;
height: 5%;
text-align: center;
color: black;
font-family: 'Poppins';
}
#searcher{
    z-index: 9;
    position: absolute;}
.Searcher {
    height: 30px;
    border-radius: 18px;
    padding-bottom: 3px;
    margin-bottom: 2px;
    display: flex;
    backface-visibility:hidden;
    font-family: 'Poppins';
}
.Evenement{
    border: 2px solid #00004D;
    background-color: white;
    border-radius: 16px;
    height: 100%;
    position: relative;
}
.wht{width: 100%;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    position: relative;
justify-content: center;
background-color: rgb(0, 0, 0, 0.7);}
.bordure{
    width: 80%;
background-color: rgb(0, 0, 0, 0.5);
display: flex;
justify-content: center;
height: calc(100vh - 60px);}
#spe{
left: 20%;
font-family: 'Poppins';
text-align: center;}
#Search{
    left: 24%;
    font-family: 'Poppins';
    text-align: center;
    padding-right:8%;
    padding-left:8%;
}
.forced{padding-top: 9px;}
.ctnr{width: 100%;
display: flex;
flex-direction: row;}
.logow{width: 30%;}
.img{width: 100%;}
.ctnr{width: 100%;
display: flex;
flex-direction: row;}
.sport{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right{width: 50%;
    display: flex;
    flex-direction: column;}
.left{width: 50%;
    display: flex;
    flex-direction: column;}
.img{width: 100%;
height: 100%;}
#Date{
    left: 22%;
    text-align: center;
    font-family: 'Poppins';}

.Lpadder{margin-top: 13px;
max-width: 100px;}

* {
    margin: 0;
}
@keyframes resizer {
    0%{width: 20%;}
    100%{width: 45%;}
}
@keyframes deresizer {
    0%{width: 45%;}
    100%{width: 20%;}
}
@keyframes fade {
    0% {opacity : 0;}
    100%{opacity: 1;}
}
@keyframes infade {
    0% {opacity : 1;}
    100%{opacity: 0;}
}
@keyframes drop {
    0% {width: 0%;
    height: 0%;}
    45%{height: 40%;
    width: 20%;}
    100%{width: 70%;
    height: 40%;}
}
@keyframes up {
    0%{width: 70%;
    height: 60%;}
    45%{height: 40%;
    width: 20%;}
    100% {width: 0%;
        height: 0%;}
}