.footer {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #707070;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
  }

  .footer a {
    flex-basis: 30%;
    text-align: center;
    padding: 10px;
  }
  
  .footer img {
    height: 70px;
    object-fit: contain;
  }

  .smaller {
    height: 40px !important;
  }